.footerMain {
  background-color: #1c4a50;
}
.footerContainer {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.footerLeftMain {
  width: 50%;
  padding-left: 60px;
}
.logo {
  width: 253px;
  height: 53px;
}
.FooterLeftText {
  width: 420px;
  padding-top: 20px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #dee2eb;
  /* white-space: normal; */
}
/* Removes margin, padding, and ensures no extra white space around the anchor tag */
.FooterLeftText a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits the color of the parent element */
  margin: 0;
  padding: 0;

  font-weight: 600;
  text-decoration: underline;
  background: none;
  border: none;
  display: inline; /* Ensures it stays inline without white spaces */
  line-height: 1; /* Prevents extra line height space */
  vertical-align: middle; /* Aligns text properly */
}

.FooterLeftText span {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;

  cursor: pointer;
  color: #dee2eb;
}

.footerRightMain {
  width: 40%;
}
.footerLinks {
  display: flex;
  gap: 30px;
}
.abtLink {
  padding-right: 0px;
}
.footerLinkText {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #dee2eb;
  cursor: pointer;
}
.footerContactMain {
  padding-top: 220px;
  padding-left: 150px;
}
.footerLocationMain {
  padding-top: 40px;
  padding-left: 150px;
}
.footerContactHeading {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
  padding-bottom: 10px;
}
.footerContactText {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #dee2eb;
}
.footerCopyRight {
  margin-top: -30px;
  padding-bottom: 40px;
  padding-left: 280px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #dee2eb;
}

@media (max-width: 900px) {
  .footerContainer {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
  }
  .footerContactMain {
    padding-top: 60px;
    padding-left: 40px;
  }
  .footerLocationMain {
    padding-left: 40px;
    padding-top: 30px;
  }
  .footerLinks {
    padding-left: 40px;
    display: flex;
    gap: 30px;
  }
  .footerLeftMain {
    width: 100%;
    padding-left: 40px;
  }
  .footerRightMain {
    width: 100%;
  }
  .FooterLeftText {
    width: 96%;
    padding-top: 20px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #dee2eb;
  }
  .footerCopyRight {
    margin-top: 30px;
    padding-bottom: 10px;
    padding-left: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .logo {
    width: 200px;
    height: 42px;
  }
  .FooterLeftText {
    width: 96%;
    padding-top: 20px;
    font-size: 14px;
  }
  .FooterLeftText span {
    padding-top: 20px;
    font-size: 14px;
  }
  .footerLinkText {
    font-size: 14px;
  }
  .footerContainer {
    padding-top: 20px;
    gap: 30px;
  }
  .footerContactMain {
    padding-top: 30px;
    padding-left: 40px;
  }
  .footerLocationMain {
    padding-left: 40px;
    padding-top: 30px;
  }
  .footerContactHeading {
    font-size: 17px;
    padding-bottom: 5px;
  }
  .footerContactText {
    font-size: 13px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #dee2eb;
  }
  .footerCopyRight {
    margin-top: 20px;
    padding-bottom: 10px;
    font-size: 11px;
  }
}

@media (max-width: 425px) {
  .footerLeftMain {
    width: 100%;
    padding-left: 18px;
  }
  .logo {
    width: 170px;
    height: 36px;
  }
  .footerContactMain {
    padding-top: 24px;
    padding-left: 18px;
  }
  .footerLocationMain {
    padding-left: 18px;
    padding-top: 24px;
  }
  .footerLinks {
    padding-left: 18px;
    gap: 30px;
  }
}

/* Modal css  */

.modalHeading {
  font-family: Josefin Slab;
  font-size: 45px;
  font-weight: 700;
  line-height: 65.7px;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.modalinput {
  padding-top: 10px;
}
.inputemail {
  width: 100%;
  height: 54px;
  border-radius: 0;
  border: 1px solid #6f6f6f;
  background: #f1f1f1;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.08px;
  text-align: left;
  padding-left: 20px;
  color: #787878;
}
.inputmessage {
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  height: 254px;
  border-radius: 0;
  border: 1px solid #6f6f6f;
  background: #f1f1f1;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.08px;
  text-align: left;
  color: #787878;
}
.modalButton {
  margin-top: 35px;
  width: 168px;
  height: 43px;
  background: #63b1bc;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.28px;
  text-align: center;
  color: #dee2eb;
  border: 0;
}

@media (max-width: 540px) {
  .modalHeading {
    font-family: Josefin Slab;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 4px;
  }
  .inputemail {
    width: 100%;
    height: 54px;
    border-radius: 0;
    border: 1px solid #6f6f6f;
    background: #f1f1f1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.08px;
    text-align: left;
    padding-left: 20px;
    color: #787878;
  }
  .inputmessage {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;
    height: 254px;
    border-radius: 0;
    border: 1px solid #6f6f6f;
    background: #f1f1f1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.08px;
    text-align: left;
    color: #787878;
  }
}
