@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 80%;
  }
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: 0 !important;
  outline: 0;
}
.modal-body {
  padding-left: 60px !important;
  padding-right: 60px !important;
  padding-bottom: 50px !important;
  padding-top: 40px !important;
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
@media (max-width: 540px) {
  .modal-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    padding-top: 30px !important;
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
  }
}
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
