.accordion {
  padding: 10px 0;
}

.accordion-header {
  display: flex;
  align-items: center;
  width: 348px;
  justify-content: space-between;
  cursor: pointer;
}
.headerunderline {
  margin-top: 10px;
  border: 1.53px solid #b1b3b3;
  width: 352.02px;
}
.icon-circle {
  width: 53.64px;
  height: 53.64px;
  background-color: #68b3b7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.check-icon {
  color: #ffffff;
  text-align: center;
}

.accordionheading {
  font-family: Raleway;
  font-size: 24.91px;
  font-weight: 600;
  line-height: 27.99px;
  text-align: left;
  color: #1c4a50;
}

.arrow {
  color: #1c4a50;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.accordion-content {
  width: 274.91px;
  margin-top: 10px;
  font-family: Raleway;
  font-size: 16.28px;
  font-weight: 300;
  line-height: 19.12px;
  text-align: left;
  color: #101820;
}

@media (max-width: 500px) {
  .accordionheading {
    font-size: 20px;
    line-height: 22px;
  }
  .icon-circle {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .accordion {
    padding: 4px 0;
  }
  .accordion-content {
    width: 274.91px;
    font-size: 15px;
  }
  .headerunderline {
    width: 300px;
  }
  .accordion-header {
    width: 300px;
  }
}
@media (max-width: 386px) {
  .accordionheading {
    font-size: 20px;
    line-height: 22px;
  }
  .icon-circle {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .accordion {
    padding: 4px 0;
  }
  .accordion-content {
    width: 250px;
    font-size: 15px;
  }
  .headerunderline {
    width: 260px;
  }
  .accordion-header {
    width: 260px;
  }
  .icon-circle {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
