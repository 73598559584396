.AboutBgMain {
  margin-top: -92px;
  background-image: url("../../Images/AboutBg.png");
  height: 720px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 1400px) {
  .AboutBgMain {
    margin-top: -92px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.mainHeading {
  padding-left: 190px;
  padding-top: 275px;
  width: 840px;
  font-family: Josefin Slab;
  font-size: 32.37px;
  font-weight: 700;
  line-height: 36.38px;
  text-align: left;

  text-transform: uppercase;
  color: #dee2eb;
}
.TeamMainHeading {
  padding-top: 50px;
  padding-bottom: 35px;
  font-family: Josefin Slab;
  font-size: 64px;
  font-weight: 700;
  line-height: 75.14px;
  text-align: center;
  color: #1c4a50;
}
.teamMain {
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teamCont {
  display: flex;
  justify-content: center;
  gap: 100px;
}
.team1main {
  padding-left: 190px;
  width: 50%;
}
.team2main {
  width: 50%;
  padding-right: 190px;
}
.teamImg {
  width: 431px;
  height: 576px;
}
.teamheading1 {
  padding-top: 20px;
  font-family: Josefin Slab;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #101820;
}
.teamheading2 {
  padding-top: 10px;
  font-family: Josefin Slab;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #1c4a50;
}
.teamtext {
  font-family: Raleway;
  padding-top: 5px;
  font-size: 17.95px;
  font-weight: 400;
  line-height: 27.3px;
  text-align: left;
  color: #101820;
}
.teamImgMain {
  display: flex;
  justify-content: center;
}

@media (max-width: 1350px) {
  .team1main {
    padding-left: 100px;
    width: 50%;
  }
  .team2main {
    padding-right: 100px;
    width: 50%;
  }
}
@media (max-width: 1180px) {
  .team1main {
    padding-left: 40px;
    width: 50%;
  }
  .team2main {
    padding-right: 20px;
    width: 50%;
  }
  .teamCont {
    display: flex;
    justify-content: center;
    gap: 60px;
  }
  .TeamMainHeading {
    padding-top: 30px;
    padding-bottom: 35px;
    font-family: Josefin Slab;
    font-size: 50px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    color: #1c4a50;
  }
}
@media (max-width: 1000px) {
  .teamCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
  }
  .team1main {
    padding-left: 40px;
    width: 96%;
  }
  .team2main {
    padding-left: 40px;

    width: 96%;
  }
}
@media (max-width: 768px) {
  .teamheading2 {
    padding-top: 10px;
    font-size: 32px;
    line-height: 38px;
  }
  .teamtext {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #101820;
  }
  .TeamMainHeading {
    padding-top: 20px;
    padding-bottom: 25px;
    font-family: Josefin Slab;
    font-size: 42px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    color: #1c4a50;
  }
}
@media (max-width: 510px) {
  .TeamMainHeading {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 38px;
    line-height: 58px;
  }
  .teamMain {
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .teamImg {
    text-align: center;
    width: 360px;
    height: 460px;
  }
  .team1main {
    padding-left: 20px;
    width: 97%;
  }
  .teamheading2 {
    padding-top: 10px;
    font-size: 28px;
    line-height: 34px;
  }
  .teamtext {
    font-size: 14px;
    line-height: 20px;
  }
  .team2main {
    padding-left: 20px;
    padding-right: 0;
    width: 96%;
  }
  .teamCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
}

@media (max-width: 400px) {
  .teamImg {
    text-align: center;
    width: 280px;
    height: 380px;
  }
}

@media (max-width: 992px) {
  .mainHeading {
    padding-left: 120px;
    padding-top: 180px;
    width: 80%;
    font-size: 30px;
    line-height: 32px;
  }
  .mainHeading span {
    font-size: 30px;
    line-height: 32px;
  }
  .AboutBgMain {
    margin-top: -92px;
    height: 550px;
  }
}
@media (max-width: 768px) {
  .mainHeading {
    padding-left: 40px;
    padding-top: 180px;
    width: 90%;
  }
}
@media (max-width: 500px) {
  .mainHeading {
    padding-left: 30px;
    padding-top: 140px;
    width: 96%;
    font-size: 26px;
    line-height: 28px;
  }
  .mainHeading span {
    font-size: 26px;
    line-height: 28px;
  }
}
