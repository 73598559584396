.homeBgMain {
  margin-top: -92px;
  background-image: url("../../Images/homeBg.png");
  height: 720px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 1400px) {
  .homeBgMain {
    margin-top: -92px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.mainHeading {
  padding-left: 240px;
  padding-top: 222px;
  width: 730px;
  font-family: Josefin Slab;
  font-size: 62px;
  font-weight: 700;
  line-height: 69.67px;
  text-align: left;
  text-transform: uppercase;
  color: #1c4a50;
}
.mainHeading span {
  padding-top: 8px;
  padding-left: 7px;
  padding-right: 7px;
  font-family: Josefin Slab;
  font-size: 62px;
  font-weight: 700;
  line-height: 69.67px;
  text-align: left;
  text-transform: uppercase;
  color: #dee2eb;
  background-color: #63b1bc;
}

@media (max-width: 992px) {
  .mainHeading {
    padding-left: 120px;
    padding-top: 180px;
    width: 80%;
    font-size: 50px;
    line-height: 60px;
  }
  .mainHeading span {
    font-size: 50px;
    line-height: 60px;
  }
  .homeBgMain {
    margin-top: -92px;
    height: 540px;
  }
}
@media (max-width: 768px) {
  .mainHeading {
    padding-left: 40px;
    padding-top: 180px;
    width: 90%;
    font-size: 40px;
    line-height: 50px;
  }
  .mainHeading span {
    font-size: 40px;
    line-height: 50px;
  }
  .homeBgMain {
    margin-top: -92px;
    height: 500px;
  }
}
@media (max-width: 500px) {
  .mainHeading {
    padding-left: 40px;
    padding-top: 180px;
    width: 90%;
    font-size: 32px;
    line-height: 46px;
  }
  .mainHeading span {
    font-size: 32px;
    line-height: 46px;
  }
  .homeBgMain {
    margin-top: -92px;
    height: 500px;
  }
}

/* Second Css  */

.secondMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  padding-bottom: 90px;
}
.secondLeftMain {
  width: 60%;
}
.secondHeading {
  width: 730px;
  font-family: Josefin Slab;
  font-size: 49.22px;
  font-weight: 700;
  line-height: 46.96px;
  text-align: left;
  color: #1c4a50;
  text-transform: uppercase;
}
.secondHeading span {
  font-family: Josefin Slab;
  font-size: 49.22px;
  font-weight: 700;
  line-height: 46.96px;
  text-align: left;
  color: #b1b3b3;
  text-transform: uppercase;
}

.textIcn {
  background-color: #63b1bc;
  width: 34.46px;
  border-radius: 100px;
  padding: 6px;
  margin-top: -12px;
  margin-left: 8px;
}
.secondText {
  padding-top: 5px;
  text-transform: uppercase;
  font-family: Josefin Slab;
  font-size: 16px;
  font-weight: 700;
  line-height: 15.26px;
  text-align: left;
  color: #000000;
}
.secondRightMain {
  width: 50%;
}
.secRightText {
  width: 586px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  line-height: 23.36px;
  text-align: left;
  color: #101820;
}
.secRightbutton {
  padding-top: 20px;
}
.secBtn {
  width: 260px;
  height: 37px;
  background: #63b1bc;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.28px;
  text-align: left;
  color: #dee2eb;
  text-transform: uppercase;
  border: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
/* .again {
  width: 343px !important;
} */

@media (max-width: 1374px) {
  .secondMain {
    gap: 30px;
  }
  .secRightText {
    width: 100%;
  }
  .secondHeading {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .secondHeading {
    font-size: 38px;
    line-height: 40px;
  }
  .secondHeading span {
    font-size: 38px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .secondHeading {
    font-size: 32px;
    line-height: 38px;
  }
  .secondHeading span {
    font-size: 32px;
    line-height: 38px;
  }
  .secondText {
    font-size: 12px;
  }
  .secRightText {
    font-size: 15px;
    line-height: 21px;
  }
  .secBtn {
    width: 220px;
    height: 34px;
    font-size: 14px;
    line-height: 24px;
    gap: 8px;
  }

  .secBtnIcn {
    width: 25px;
  }
  .secondMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .secondLeftMain {
    width: 100%;
  }
  .secondRightMain {
    width: 100%;
  }
  .textIcn {
    width: 28.46px;
    margin-top: -8px;
    margin-left: 6px;
  }
}

/* Third Css  */

.ThirdCont1 {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}
.ThirdCont2 {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}
.thirdSec1Main {
  border: 1px solid;
  background: #63b1bc;
  width: 436px;
  height: 236px;
  padding-top: 40px;
  padding-left: 28px;
  padding-bottom: 30px;
}
.thirdSec2Main {
  background: #d9d9d6;
  width: 436px;
  height: 236px;
  padding-top: 40px;
  padding-left: 28px;
  padding-bottom: 30px;
}
.thirdSec1Heading {
  width: 178px;
  font-family: Josefin Slab;
  font-size: 30px;
  font-weight: 700;
  line-height: 33.71px;
  text-align: left;
  color: #1c4a50;
  text-transform: uppercase;
}
.thirdSec1Text {
  padding-top: 10px;
  width: 351px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.78px;
  text-align: left;
  color: #101820;
}

@media (max-width: 500px) {
  .thirdSec1Main {
    width: 90%;
    height: 206px;
    padding-top: 30px;
    padding-left: 24px;
    padding-bottom: 20px;
  }
  .thirdSec2Main {
    width: 90%;
    height: 220px;
    padding-top: 30px;
    padding-left: 24px;
    padding-bottom: 20px;
  }
  .thirdSec1Text {
    padding-top: 10px;
    width: 94%;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    color: #101820;
  }
}

/* Fourth css  */

.fourthMain {
  margin-top: 90px;
  margin-bottom: 90px;
  background: #d9d9d654;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.fourthCont {
  display: flex;
  gap: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.fourthLeftMain {
  padding-top: 26px;
  padding-left: 28px;
}
.LeftheadingBtn {
  width: 102px;
  height: 37px;
  border: 1px solid #101820;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.44px;
  text-align: center;
  color: #101820;
}
.fourthLeftHeading {
  padding-top: 70px;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #101820;
}
.fourthLeftText {
  width: 72%;
  padding-top: 30px;
  font-family: Raleway;
  font-size: 17px;
  font-weight: 300;
  line-height: 19.96px;
  text-align: left;
  color: #101820;
}
.fourthRightCont {
  position: relative;
}
.fourthBox1Main {
  position: absolute;
  left: -35%;
  top: 5%;

  padding: 30px;
  width: 193px;
  height: 165px;
  background: #ffffff;
  box-shadow: -2px 4px 6.8px 0px #6161612e;
}
.fourthBox2Main {
  position: absolute;
  top: 70%;
  left: -21%;
  display: flex;
  align-items: center;
  gap: 15px;

  padding-left: 20px;
  width: 302px;
  height: 80px;
  background: #ffffff;
  box-shadow: -2px 4px 6.8px 0px #6161612e;
}
.fourthBoxIconMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 39px;
  background: #1c4a50;
}
.boxIconHeading {
  padding-top: 8px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.98px;
  text-align: left;
  color: #101820;
}
.boxIconHeading2 {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.98px;
  text-align: left;
  color: #101820;
}

@media (max-width: 992px) {
  .fourthLeftMain {
    padding-top: 26px;
    padding-left: 18px;
  }
  .fourthImg {
    width: 360px;
    height: 350px;
  }
  .fourthLeftHeading {
    padding-top: 40px;
    font-family: Raleway;
    font-size: 36px;
    font-weight: 500;
    line-height: 42.26px;
    text-align: left;
    color: #101820;
  }
  .fourthLeftText {
    width: 70%;
    padding-top: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.96px;
    text-align: left;
    color: #101820;
  }
}

@media (max-width: 845px) {
  .fourthLeftText {
    width: 95%;
    padding-top: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.96px;
    text-align: left;
    color: #101820;
  }
  .fourthCont {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .fourthMain {
    margin-top: 40px;
    margin-bottom: 40px;
    background: #d9d9d654;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 660px) {
  .fourthBox2Main {
    position: absolute;
    top: 70%;
    left: 0%;
    gap: 10px;
    padding-left: 10px;
    width: 240px;
    height: 60px;
  }
  .fourthBox1Main {
    left: 0%;
    top: 5%;
    padding: 10px;
    width: 140px;
    height: 110px;
  }
  .boxIconHeading {
    padding-top: 5px;
    font-size: 14px;
    line-height: 15px;
  }
  .boxIconHeading2 {
    font-size: 14px;
    line-height: 16.98px;
  }
}

@media (max-width: 425px) {
  .fourthLeftHeading {
    padding-top: 40px;
    font-size: 32px;
    line-height: 40px;
  }
  .fourthLeftText {
    padding-top: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 19.96px;
    text-align: left;
    color: #101820;
  }
  .fourthImg {
    width: 100%;
    height: 300px;
  }
}
/* Fifth css  */

.FifthMain {
  padding-top: 30px;
  padding-bottom: 80px;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.fifthMainHeading {
  padding-bottom: 50px;
  font-family: Josefin Slab;
  font-size: 49.22px;
  font-weight: 700;
  line-height: 55.31px;
  text-align: left;
  color: #1c4a50;
  text-transform: uppercase;
}
.AccordionMainCont {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 calc(33% - 20px);
  gap: 40px;
}
.fifthAccordianMain {
  display: flex;
}

@media (max-width: 1390px) {
  .AccordionMainCont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1 1 calc(33% - 20px);
    gap: 40px;
  }
}

@media (max-width: 992px) {
  .fifthMainHeading {
    padding-bottom: 50px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .FifthMain {
    padding-top: 20px;
    padding-bottom: 35px;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }
  .fifthMainHeading {
    padding-bottom: 30px;
    font-size: 32px;
    line-height: 50px;
    text-align: center;
  }
}

/* Modal css  */

.modalHeading {
  font-family: Josefin Slab;
  font-size: 45px;
  font-weight: 700;
  line-height: 65.7px;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.modalinput {
  padding-top: 10px;
}
.inputemail {
  width: 100%;
  height: 54px;
  border-radius: 0;
  border: 1px solid #6f6f6f;
  background: #f1f1f1;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.08px;
  text-align: left;
  padding-left: 20px;
  color: #787878;
}
.inputmessage {
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  height: 254px;
  border-radius: 0;
  border: 1px solid #6f6f6f;
  background: #f1f1f1;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.08px;
  text-align: left;
  color: #787878;
}
.modalButton {
  margin-top: 35px;
  width: 168px;
  height: 43px;
  background: #63b1bc;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.28px;
  text-align: center;
  color: #dee2eb;
  border: 0;
}

@media (max-width: 540px) {
  .modalHeading {
    font-family: Josefin Slab;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 4px;
  }
  .inputemail {
    width: 100%;
    height: 54px;
    border-radius: 0;
    border: 1px solid #6f6f6f;
    background: #f1f1f1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.08px;
    text-align: left;
    padding-left: 20px;
    color: #787878;
  }
  .inputmessage {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;
    height: 254px;
    border-radius: 0;
    border: 1px solid #6f6f6f;
    background: #f1f1f1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.08px;
    text-align: left;
    color: #787878;
  }
}
