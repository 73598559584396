.logoImg {
  width: 246px;
  height: 50px;
}
.navLink {
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.98px;
  text-align: left;
  color: #1c4a50;
}

.brokerImg {
  margin-right: 4px;
  margin-top: -3px;
  width: 129px;
  height: 34px;
}
.navLink:hover {
  color: #1c4a50;
}
.navLink:active {
  color: #1c4a50 !important;
}
.navLink:focus {
  color: #1c4a50 !important;
}
.headerButton {
  background: linear-gradient(to right, #ffffff, #ffffff);
  color: #bd9b60;
  width: 211px;
  height: 51px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  padding-left: 12px;
  align-items: center;
  justify-content: center;
  transition: color 0.4s ease-in-out;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.btnIcn {
  padding-top: 2px;
}
.headerButton img.btnIcn {
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
}
.headerButton:hover {
  color: #bd9b60; /* Optionally change text color on hover */
}
.headerButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #1c4a50,
    #1c4a50
  ); /* Hover background color */
  transition: left 0.5s ease-in-out; /* Smooth transition for the background */
  z-index: -1;
  transition: left 0.5s ease-in-out;
}

.headerButton:hover::before {
  left: 0;
}

.headerButton:hover img.btnIcn {
  transform: translateX(5px);
}

.headerButton > * {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.5px) {
  .headerButton {
    border: 1px solid;
  }
}
@media (max-width: 768px) {
  .logoImg {
    width: 200px;
    height: 42px;
  }
}
@media (max-width: 500px) {
  .logoImg {
    width: 170px;
    height: 36px;
  }
}
