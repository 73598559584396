.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.mb-3 {
  margin-bottom: 0 !important;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 0;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 1rem;
  --bs-navbar-nav-link-padding-x:1.2rem;
  
}
.navbar-toggler {
 background-color: white !important;
  /* border: 0 !important; */
 
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow:none !important;
}

@media (max-width:500px) {
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;
  }
  .navbar-toggler {
    font-size: 14px !important;
     /* border: 0 !important; */
    
   }

}
@media (max-width:991.5px) {
  a{
    padding-right:20px ;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  }
  .offcanvas-header {
  
    padding-bottom: 35px;
}
}
a{
  padding-right:20px ;
  text-decoration: none;
  display: flex;
  align-items: center;
}